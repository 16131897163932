import React, { FC } from 'react'
import { Flex, Box, Divider, Link, useMediaQuery } from '@chakra-ui/react'
import { useQuery } from '@apollo/client'

import { ByEclass } from '../../assets'
import { GET_PORTAL_FOOTER } from '../../services/graphql'

/*
 * Footer component
 * Este componente representa el footer del login
 */

interface FooterProps {
  portalId?: number
  showInfo: boolean
}

export const Footer: FC<FooterProps> = ({ portalId, showInfo = true }) => {
  const [isDesktop] = useMediaQuery('(min-width: 932px)')
  if (showInfo) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { data, loading } = useQuery(GET_PORTAL_FOOTER, {
      variables: {
        PortalFooterInput: {
          portalId: portalId,
        },
      },
    })

    if (
      !data ||
      !data.getPortalFooter ||
      !data.getPortalFooter.status ||
      data.getPortalFooter.status.success === false ||
      loading
    )
      return null

    return (
      <Flex justifyContent="center" mt="50px" display="block">
        <Box justifyContent="center" display="flex">
          <ByEclass />
        </Box>
        <Box my={4} px={isDesktop ? 8: 0}>
          <Divider color="#e6e6e6" opacity="1" />
        </Box>
        <Box display="flex" justifyContent="center">
          {data.getPortalFooter.Links.map((link: any, index: number) => (
            <React.Fragment key={index}>
              <Link
                color="#0189FF"
                fontFamily="Roboto"
                fontSize="14px"
                fontWeight="500"
                href={link.url}
                target={link.target}
                textDecor="underline"
                >
                {console.log(index, link.title)}
                {link.title}
              </Link>
              {index < data.getPortalFooter.Links.length-1 && data.getPortalFooter.Links.length > 1 && (
                <Divider orientation="vertical" color="#e6e6e6" opacity="1" mx={2} h="16px" />
              )}
            </React.Fragment>
          ))}
        </Box>
      </Flex>
    )
  } else {
    return (
      <Flex justifyContent="center" mt="50px" display="bock">
        <Box justifyContent="center" display="flex">
          <ByEclass />
        </Box>
      </Flex>
    )
  }
}
